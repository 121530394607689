
import { ref, defineComponent, onMounted, Ref, watch } from 'vue'
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import Image from 'primevue/image'
import AutoComplete from 'primevue/autocomplete'
import { ICompany } from '@/models/jobBank/interfaces/company'
import { CompaniesAPI } from '@/api/companies.api'
import { JobBankRouteNames } from '@/router/route-names'

export default defineComponent({
  emits: ['nextPage', 'prevPage', 'saveChanges'],
  props: {
    editFormOfferData: {
      type: Object
    },
    isEditing: {
      type: Boolean
    }
  },

  components: {
    Button,
    Dropdown,
    Image,
    AutoComplete
  },
  setup (props, { emit }) {
    const companies: Ref<ICompany[]> = ref([])
    const filteredCompanies = ref()
    const selectedCompany = ref()
    const sector = ref()
    const logo = ref()
    const error = ref()
    const companyId = ref()

    const getCompanies = async () => {
      companies.value = await CompaniesAPI.findAllCompanies()
    }

    const searchCompany = (event: any) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredCompanies.value = [...companies.value]
        } else {
          filteredCompanies.value = companies.value.filter((company: any) => {
            return company.name.toLowerCase().startsWith(event.query.toLowerCase())
          })
        }
      }, 250)
    }
    watch(selectedCompany, (current, old) => {
      sector.value = selectedCompany.value.sector
      logo.value = selectedCompany.value.logo
      error.value = false
      companyId.value = selectedCompany.value._id
    })

    const isFormCorrect = () => {
      const found = companies.value.find(element => element.name === selectedCompany.value.name)
      if (selectedCompany.value === undefined || selectedCompany.value === '' || !found) {
        error.value = true
        return false
      } else {
        error.value = false
        return true
      }
    }

    const next = () => {
      if (isFormCorrect()) {
        emit('nextPage', { formOfferData: { company: { name: selectedCompany.value.name, sector: sector.value, logo: logo.value }, companyId: companyId.value }, pageIndex: 1 })
      }
    }

    const back = () => { emit('prevPage', { pageIndex: 1 }) }

    const saveChanges = () => {
      if (isFormCorrect()) {
        emit('saveChanges', { formOfferData: { company: { name: selectedCompany.value.name, sector: sector.value, logo: logo.value }, companyId: companyId.value } })
      }
    }

    onMounted(() => {
      getCompanies()
      if (props.isEditing) {
        selectedCompany.value = props.editFormOfferData?.company[0]
        companyId.value = props.editFormOfferData?.companyId
      }
    })

    return {
      back,
      next,
      companies,
      searchCompany,
      filteredCompanies,
      sector,
      logo,
      selectedCompany,
      isFormCorrect,
      error,
      JobBankRouteNames,
      saveChanges
    }
  }
})
